.footer {
  background-color: var(--cl-navy);
  color: var(--cl-white);
  padding: 40px 0 24px;

  @media (max-width: 768px) {
    padding: 40px 0 32px;

    &__contacts {
      flex-direction: column;
    }

    &__right-contact {
      width: 100%;
    }
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 24px;
    border-image-slice: 1;
    border-bottom-width: 1px;
    border-style: solid;
    border-image-source: linear-gradient(90deg, #a7904f 0%, #ead4a2 100%);
    background-origin: border-box;
    background-clip: padding-box, border-box;
    gap: 30px;

    .logo {
      width: 288px;

      @media (max-width: 370px) {
        width: 270px;
      }
    }

    @media (max-width: 768px) {
      padding-bottom: 32px;
    }

    @media (max-width: 980px) {
      gap: 40px;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__contacts {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 18px;
    max-width: 617px;

    @media (max-width: 980px) {
      align-items: flex-start;
    }
  }

  &__left-contacts {
    display: flex;
    flex-direction: column;
    gap: 18px;
  }

  &__right-contact {
    flex: 1;
    display: flex;
    align-items: center;
  }

  &__contact {
    a {
      display: inline-flex;
      align-items: center;
      gap: 12px;

      i::before {
        background: linear-gradient(90.42deg, #ead4a2 -0.02%, #a7904f 100.03%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 28px;
      }

      p {
        color: var(--cl-white);
        font-variant-numeric: lining-nums proportional-nums;
        font-feature-settings: 'liga' off;
        font-family: var(--font-main);
        font-size: 20px;
        font-weight: 400;
        line-height: 1.4;
        transition: all 0.3s ease;
      }

      &:hover {
        p {
          color: var(--cl-beige);
        }
      }
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
    gap: ac(50px, 32px);
    margin-top: 24px;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
    }

    .footer-member-cont {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 375px;

      @media (max-width: 768px) {
        order: 2;
        flex-direction: row-reverse;
        align-items: flex-end;
        justify-content: flex-end;
        gap: 16px;
        width: 100%;
      }

      &__pic {
        position: relative;
        overflow: hidden;
        width: 125px;

        @media (max-width: 600px) {
          width: 156px;
        }
        /* margin: 0 ac(188px, 50px); */

        @media (max-width: 768px) {
          margin: 0;
        }

        &::before {
          content: '';
          display: block;
          padding-top: 44.8%;
        }

        img {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: 0.3s;
        }
      }
    }
  }

  &__legal {
    font-variant-numeric: lining-nums proportional-nums;
    font-family: var(--font-main);
    font-size: 16px;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: 0.48px;
    width: 365px;

    @media (max-width: 768px) {
      order: 3;
      max-width: 70%;
    }

    @media (max-width: 600px) {
      margin-top: -15px;
    }
  }

  &__cookies {
    a {
      font-variant-numeric: lining-nums proportional-nums;
      font-family: var(--font-main);
      font-size: 16px;
      font-weight: 500;
      line-height: 1.2;
      letter-spacing: 0.48px;
      text-decoration-line: underline;
      transition: all 0.3s ease;

      &:hover {
        color: var(--cl-beige);
      }
    }
  }

  &__socials {
    display: flex;
    align-items: center;
    margin-left: auto;

    @media (max-width: 768px) {
      margin-left: auto;
      margin-right: auto;
    }

    @media (max-width: 768px) {
      order: 1;
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 56px;
      height: 56px;
      border-radius: 50%;
      border: 1px solid var(--cl-beige);
      transition: all 0.3s ease;

      &:hover {
        background-color: var(--cl-beige);

        i {
          background: var(--cl-navy);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }

      @media (max-width: 768px) {
        width: 71px;
        height: 71px;
      }

      @media (max-width: 370px) {
        width: 56px;
        height: 56px;
      }

      &:not(:first-of-type) {
        margin-left: -8px;
      }

      i {
        background: linear-gradient(90.42deg, #ead4a2 -0.02%, #a7904f 100.03%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 19px;

        @media (max-width: 768px) {
          font-size: 28px;
        }

        @media (max-width: 370px) {
          font-size: 19px;
        }
      }
    }
  }
}
