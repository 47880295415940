@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400..600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600&display=swap');

:root {
  --cl-navy: #0a0e27;
  --cl-navy-lt: #0C1131;
  --cl-white: #fff;
  --cl-gold: #a7904f;
  --cl-beige: #ead4a2;

  --font-main: 'Playfair Display', sans-serif;
  --font-second: 'Roboto', sans-serif;

  /* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
  --mobile-menu-start-point: 1200;

  --vh: 1vh;
}

/* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
/* @custom-media --mobile-menu-start-point (max-width: 1200px); */

/* --mobile-menu-end-point must be greater than 1 of the  --mobile-menu-start-point and @custom-media --mobile-menu-start-point */
/* @custom-media --mobile-menu-end-point (min-width: 1201px); */
