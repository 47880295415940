.fp-watermark {
  display: none;
}

html {
  scrollbar-width: thin;
  scrollbar-color: var(--cl-primary) transparent;
}

body {
  position: relative;
  min-width: 320px;
  margin: auto;
  background: var(--cl-black);

  color: var(--cl-black);
  font-family: var(--font-main);

  overflow-x: hidden;

  min-height: calc(100 * var(--vh));
  display: flex;

  flex-direction: column;
  align-items: stretch;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: var(--cl-light-grey);
    border-radius: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--cl-red);
    border-radius: 0;
  }
}

.swiper-wrapper {
  left: 0 !important;
}

#site {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 120px;
    background: var(--cl-navy);
    content: '';
  }
}

.main {
  flex-grow: 1;
}

.cont {
  margin: 0 auto;
  max-width: 1224px;
  width: perc(1224);

  @media (max-width: 1023px) {
    width: 100%;
    padding: 0 24px;
  }
}

section {
  position: relative;
}

.logo {
  transition: 0.3s ease;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &:hover {
    transform: scale(1.06);
  }
}

.btn {
  width: max-content;
  height: 48px;
  padding: 0 32px;
  color: var(--cl-white);
  font-family: var(--font-main);
  font-size: 18px;
  font-weight: 500;
  line-height: 48px;
  letter-spacing: 1.8px;
  text-transform: uppercase;
  border: 1px solid var(--cl-beige);
  border-radius: 1000px;
  transition: all 0.3s ease;

  &__header {
    color: var(--cl-white);
  }
}

.combo-btn {
  display: inline-flex;
  cursor: pointer;

  & > :first-child {
    padding-right: 40px;
  }
  & > :last-child {
    padding: 0;
    width: 48px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    margin-left: -10px;
    color: var(--cl-beige);
  }

  &:hover > :first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
  }

  &:hover > :last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;
  }
}

.swiper-buttons {
  width: fit-content;
  display: flex;
  gap: 15px;
  position: relative;
  left: auto;
  top: auto;
  bottom: auto;
  margin: 0 auto;

  .swiper-button {
    position: relative;
    transform: none;
    margin: 0;
    left: auto;
    right: auto;
    top: auto;
    bottom: auto;
    display: flex;
    width: 48px;
    height: 48px;
    justify-content: center;
    align-items: center;
    color: var(--cl-navy);
    border: 1px solid var(--cl-beige);
    border-radius: 50%;
    transition: 0.3s;

    &::after {
      display: none;
    }

    &::before {
      content: '\e901';
      display: block;
      font-family: 'icomoon' !important;
    }

    &.swiper-button-prev {
      &::before {
        content: '\e900';
      }
    }

    &:hover {
      background: var(--cl-beige);
      color: var(--cl-white);
    }

    &.swiper-button-lock {
      display: none;
    }
  }
}

/* *[data-simplebar] {
  .simplebar-scrollbar {
    width: 2px;
    background: var(--cl-beige);
    border-radius: 4px;

    &:before {
      content: none;
    }
  }
} */

#bold-credits {
  width: 148px;
  transition: ease-in-out 0.25s;

  &::before,
  &::after {
    display: none;
  }

  &:hover {
    transform: scale(1.1);
  }

  @mixin max-sm {
    width: 159px;
  }
}

#site.cookie-terms-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;

  .main {
    flex-grow: 1;
    padding: 0;
  }
}

.cookie-terms-section {
  padding: ac(250px, 140px) 0 ac(150px, 50px);
  p {
    padding: 0;
    margin-bottom: 10px;
  }
  ul,
  ol {
    padding-left: 20px;
    li {
      p {
        margin-bottom: 0;
      }
    }
  }

  ul {
    list-style: circle;
  }

  ol {
    list-style: decimal;
  }

  a {
    display: inline;
    text-decoration: underline;
    transition: 0.2s;

    &:hover {
      opacity: 0.7;
    }
  }
}

.single-page-404 {
  min-height: 100vh;
  padding: 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1,
  p {
    margin-bottom: 15px;
    padding: 0;
    text-align: center;
  }

  .btn {
    display: inline-block;
    margin-top: 20px;
    color: var(--cl-black) !important;
    transition: all 0.3s ease !important;

    span {
      position: relative;
      top: -2px;
    }

    &:hover {
      color: var(--cl-beige) !important;
    }
  }
}
