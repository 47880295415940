/* ----------------------- Common desktop header ----------------------- */
.header {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  height: 108px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.3s ease-in-out;
  background-color: var(--cl-navy);

  &:after {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    background: var(--cl-beige);
    content: '';
    transition: 0.3s;
    z-index: 1;
    transition-delay: 0.2s;
  }

  &.absolute {
    position: absolute;
  }

  &.fixed {
    position: fixed;
  }

  &:before {
    position: absolute;
    inset: 0;
    content: '';
    z-index: -1;
    background-color: transparent;
    opacity: 0;
    transition: all 0.25s ease;
  }

  &.scrolled {
    height: 72px;

    .navbar-nav {
      &::before {
        @media (max-width: 1200px) {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          width: 100%;
          height: 72px;
          background-color: var(--cl-navy);
        }
      }
    }

    &:after {
      transition-delay: 0s;
      opacity: 0;
    }

    &:not(.open-menu) {
      background-color: transparent;
      -webkit-backdrop-filter: blur(16px);
      backdrop-filter: blur(16px);
      padding: ac(20px, 10px) 0;

      &:before {
        opacity: 0.5;
        background-color: var(--cl-navy-lt);
      }

      .logo {
        width: 190px;

        @mixin max-xs {
          width: 165px;
        }
      }
    }
  }

  .combo-btn:not(.menu__combo-btn) {
    @media (max-width: 1200px) {
      display: none;
    }
  }

  .menu__combo-btn {

    & > :first-child {
      width: 287px;
      text-align: center;

      @media (max-width: 374px) {
        width: 230px;
      }
    }
    & > * {
      color: var(--cl-navy);
      border-color: var(--cl-navy);
    }
  }

  .logo {
    height: 100%;
    width: 236px;
    @mixin aspect-ratio 236, 53;
    flex-shrink: 0;
    transition: transform 0.25s ease, width 0.25s ease;
    z-index: 100;
    margin-right: auto;

    @media (max-width: 768px) {
      width: 176px;
      @mixin aspect-ratio 176, 40;
    }

    &:hover {
      transform: scale(1.06);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: left;
    }

    @mixin max-xs {
      width: 180px;
    }
  }

  .navbar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0;
  }

  .burger {
    display: none;
  }

  .navbar-nav {
    position: relative;
    display: flex;
    align-items: center;
    /* flex-grow: 1; */
    height: 53.5px;

    .decor {
      position: absolute;
      bottom: -17%;
      left: -55%;

      @media (min-width: 1200px) {
        display: none;
      }
    }

    &::before {
      @media (max-width: 1200px) {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        width: 100%;
        height: 108px;
        background-color: var(--cl-navy);
      }
    }

    .menu-list {
      display: flex;
      flex-grow: 1;
    }

    &__decor {
      display: none;
    }
  }

  .menu {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    /*transition: opacity 0.2s ease;
    opacity: 0;*/

    &__socials {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-top: 24px;

      i {
        transition: all 0.3s ease;
        font-size: 23px;
        color: var(--cl-navy);
      }

      a {
        &:hover {
          i {
            color: var(--cl-white);
          }
        }
      }
    }

    @mixin mobile-menu-end-point {
      width: auto;
      flex: 1;
      /* padding-left: ac(60px, 30px); */
      align-items: center;

      .simplebar-content {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .menu-list {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: ac(36px, 20px);
        }

        &:after {
          display: none;
        }
      }
    }

    &:not([data-simplebar='init']) {
      display: none;
    }
  }

  .menu__anchors {
    display: flex;
    flex-direction: column;
    gap: 16px;

    a {
      display: inline-flex;
      width: fit-content;
      transition: all 0.3s ease;
      color: var(--cl-navy);
      font-variant-numeric: oldstyle-nums proportional-nums;
      font-family: var(--font-main);
      font-size: 26px;
      font-weight: 400;
      line-height: 1.32;

      &:hover {
        color: var(--cl-white);
      }
    }
  }

  .menu__contact-header {
    display: flex;
    flex-direction: column;
    gap: 17px;
    margin-bottom: 18.5px;

    a {
      i::before {
        transition: all 0.3s ease;
        background: var(--cl-navy);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 28px;
      }

      p {
        color: var(--cl-navy);
        font-variant-numeric: lining-nums proportional-nums;
        font-feature-settings: 'liga' off;
        font-family: var(--font-main);
        font-size: 22px;
        font-weight: 500;
        line-height: 1.4;

        @media (max-width: 374px) {
          font-size: 19px;
        }
      }

      &:hover {
        p {
          color: var(--cl-white);
        }
      }
    }
  }

  .menu-item {
    position: relative;

    &.dropdown {
      & > .menu-link {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;

        &::after {
          content: '\e999';
          font-family: 'icomoon';
          font-size: ac(10px, 8px);
          padding-left: 10px;
        }
      }
    }
  }

  .menu-additional-content {
    display: none;

    @mixin mobile-menu-start-point {
      /*transform: translateY(-8px);*/
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      margin-top: auto;

      .btn {
        width: 100%;
      }
    }
  }

  &:not(.header-mobile) {
    .menu-item {
      @mixin mobile-menu-end-point {
        padding: 5px 0;
      }

      &:hover {
        @mixin mobile-menu-end-point {
          .dropdown-menu {
            display: block;
          }
        }
      }
    }
  }

  .menu-link {
    position: relative;
    font-size: 15px;
    font-family: var(--font-main);
    line-height: normal;
    font-weight: 600;
    color: var(--cl-grey);
    transition: all 0.3s ease;
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      bottom: -2px;
      right: 0;
      width: 0;
      height: 2px;
      background: var(--cl-grey);
      transition: all 0.3s ease;
    }

    &:hover {
      color: var(--cl-red);

      &::before {
        width: 100%;
        left: 0;
        background: var(--cl-red);
      }
    }
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 100%;
    padding: 10px 10px 10px 15px;
    border-radius: 10px;
    background: var(--cl-grey);
    box-sizing: content-box;
    display: none;

    li {
      .menu-link {
        padding: 5px 0;
        text-transform: capitalize;
      }
    }
  }
}

/* end of Common desktop header */

/* ----------------------- Common desktop header Mobile HEADER ----------------------- */

@mixin mobile-menu-start-point {
  .header:not(.header-mobile) {
    .burger,
    .menu-toggle {
      display: flex;
    }

    .navbar-nav {
      position: fixed;
      right: 0;
      top: 0;
      width: 50%;
      height: calc(var(--vh, 1vh) * 100);
      min-width: 320px;
      padding-top: 112px;
      background: linear-gradient(360deg, #a7904f 0%, #ead4a2 100%);

      padding-right: 5px;
      padding-bottom: 40px;
      transition: transform 0.3s ease-out;
      overflow: hidden;

      @media screen and (max-height: 730px) {
        padding-top: 110px;
      }

      &.nav-slide-left {
        right: auto;
        left: 0;
        transform: translateX(-100%);

        &.active {
          transform: translateX(0);
        }
      }

      &.nav-slide-right {
        right: 0;
        left: auto;
        transform: translateX(100%);

        &.active {
          transform: translateX(0);
        }
      }

      &.nav-slide-down {
        right: auto;
        left: 0;
        transform: translateY(-100%);

        &.active {
          transform: translateY(0);
        }
      }

      &.nav-slide-up {
        right: auto;
        left: 0;
        transform: translateY(100%);

        &.active {
          transform: translateY(0);
        }
      }

      .menu {
        width: 100%;
        max-height: 100%;
        min-height: 100%;
        flex-direction: column;
        justify-content: flex-start;
        padding-left: 30px;
        padding-right: 30px;
        align-items: flex-start;
        align-self: center;
        position: relative;
        z-index: 2;
        overflow-x: hidden;
        overflow-y: auto;

        .simplebar-content {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          width: 100%;
          height: 100%;

          .menu-list {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
          }
        }

        .simplebar-track.simplebar-vertical {
          width: ac(6px, 4px);
          background: var(--cl-medium-grey);
          border-radius: 4px;
          /*transform: translateX(ac(6px, 4px));*/
          .simplebar-scrollbar {
            background: var(--cl-red);
            border-radius: 4px;
            &:before {
              content: none;
            }
          }
        }
      }

      .menu-link {
        padding: 0;
        font-size: 20px;
        font-weight: 500;
        margin-left: 30px;
      }

      .menu-item {
        width: 100%;
        margin-bottom: 18px;
        padding-bottom: 18px;
        border-bottom: 1px solid var(--cl-light-grey);

        &:last-of-type {
          border-bottom: none;
          margin-bottom: 70px;

          @media screen and (max-height: 730px) {
            margin-bottom: 28px;
          }
        }
      }

      @mixin max-sm {
        width: 100%;

        &:after {
          position: absolute;
          content: '';
          left: 0;
          top: 0;
          height: 80px;
          width: calc(100% - 25.6vw);
          background: var(--cl-light-grey);
        }
      }
    }

    .dropdown-menu {
      position: relative;
      top: 0;
    }
  }
}

/* ----------------------- HEADER COMPONENTS ----------------------- */
.burger,
.menu-toggle {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 74.6px;
  height: 48px;
  padding: 15px 24px;
  cursor: pointer;
  transition: transform 0.3s;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border: 1px solid transparent;
    border-radius: 1000px;
    padding: 15px 24px;
    background: linear-gradient(90deg, #a7904f 0%, #ead4a2 100%) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }

  div {
    height: 1.5px;
    background: linear-gradient(90.42deg, #ead4a2 -0.02%, #a7904f 100.03%);
    width: 26.6px;
    transition: transform 0.3s, opacity 0.1s;
    border-radius: 5px;

    &:nth-of-type(1) {
      transform-origin: left top;
    }

    &:nth-of-type(2) {
      transform-origin: left center;
    }

    &:nth-of-type(3) {
      transform-origin: left bottom;
    }
  }

  &:hover {
    transform: scale(1.1);
  }

  &.active {
    div {
      background: white;
      &:nth-of-type(1) {
        transform: rotate(45deg) translate(12%, -261%);
      }

      &:nth-of-type(2) {
        transform: translateX(-100%);
        opacity: 0;
      }

      &:nth-of-type(3) {
        transform: rotate(-45deg) translate(12%, 261%);

      }
    }
  }
}

.header-close-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 77;
  background: rgba(0, 0, 0, 0.4);
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease;

  &.active {
    visibility: visible;
    pointer-events: auto;
    opacity: 1;
    cursor: pointer;
  }
}

/* end of HEADER COMPONENTS */
