.section-home-hero {
  background-color: var(--cl-navy);
  position: relative;
  margin-top: 108px;
  overflow: hidden;
  border-bottom: 1px solid var(--cl-beige);

  &__video {
    position: absolute;
    inset: 0;

    video {
      position: relative;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 1;
    }

    .video-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(10, 14, 39, 0.7);
      pointer-events: none;
      z-index: 2;
    }
  }

  &__bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    max-width: 1440px;
    width: 100%;
    z-index: 3;
  }

  &__decor {
    position: absolute;
    right: -33%;
    top: 0;
    z-index: 3;
    width: ac(958px, 657px);
    min-width: 657px;

    @media (max-width: 1024px) {
      right: -50%;
    }

    @media (max-width: 768px) {
      right: 0;
      transform: translateX(90%);
    }
  }

  &__wr {
    position: relative;
    color: var(--cl-white);
    max-width: 40%;
    z-index: 4;
    padding: ac(216px, 40px) 0 ac(108px, 32px);
    min-height: calc(100vh - 108px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    @media (min-height: 1200px) {
      min-height: 1200px;
    }

    @media (max-width: 1024px) {
      @media (min-width: 641px) {
        min-height: 600px;
      }
    }

    @media (max-width: 640px) {
      justify-content: center;
    }

    h1 {
      font-variant-numeric: oldstyle-nums proportional-nums;
      font-family: var(--font-main);
      font-size: ac(80px, 44px);
      font-weight: 600;
      line-height: 1.08;
    }

    .combo-btn {
      margin-top: 40px;

      @media (min-width: 1440px) {
        margin-top: 40px;
      }

      @media (min-width: 1366px) {
        margin-top: 25px;
      }

      @media (max-width: 350px) {
        .btn:first-of-type {
          width: 85%;
          padding-right: 24px;
          padding-left: 24px;
        }
      }
    }

    @media (max-width: 1024px) {
      max-width: 60%;
    }

    @media (max-width: 768px) {
      max-width: 87.77%;
    }
  }

  &__descr {
    margin-top: 24px;

    & > * {
      font-feature-settings: 'liga' off;
      font-family: var(--font-main);
      font-size: ac(20px, 18px);
      font-weight: 400;
      line-height: 1.4;
      letter-spacing: 0.6px;

      @media (max-width: 350px) {
        max-width: 95%;
      }
    }

    & > * + p {
      margin-top: 20px;
    }
  }
}

.section-home-stats {
  padding: ac(64px, 24px) 0;
  background-color: var(--cl-navy);
  color: var(--cl-white);

  &__wr {
    display: flex;
    align-items: stretch;
    gap: ac(24px, 7.5px);

    @media (max-width: 768px) {
      flex-wrap: wrap;
      justify-content: center;
    }

    .home-stats-card {
      flex-basis: 288px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      text-align: center;

      @media (max-width: 768px) {
        flex-basis: 155px;
        min-height: 119px;
      }

      &__top {
        display: flex;
        align-items: center;
        gap: 5px;

        span {
          font-variant-numeric: lining-nums proportional-nums;
          font-family: var(--font-main);
          font-size: ac(64px, 36px);
          font-weight: 500;
          line-height: 1.375;
        }

        i {
          margin-top: 12px;
          font-variant-numeric: oldstyle-nums proportional-nums;
          font-size: ac(30px, 22px);
          font-weight: 600;
          line-height: 1.375;
          background: var(
            --Gradient-I,
            linear-gradient(90deg, #a7904f 0%, #ead4a2 100%)
          );
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }

      p {
        color: var(--White, #fff);
        font-variant-numeric: oldstyle-nums proportional-nums;
        font-family: var(--font-main);
        font-size: ac(26px, 18px);
        font-weight: 600;
        line-height: 1.2;
      }
    }
  }
}

.section-home-purpose {
  background-color: var(--cl-white);
  padding: 108px 0;

  @media (max-width: 600px) {
    padding: 24px 0 40px;
  }

  &__wr {
    display: flex;
    align-items: center;
    gap: ac(56px, 32px);

    @media (max-width: 1023px) {
      flex-direction: column;
    }
  }

  &__pic {
    width: 49.02%;
    position: relative;
    overflow: hidden;
    border-radius: 32px;

    @media (max-width: 900px) {
      width: ac(500px, 327px);
    }

    @media (max-width: 374px) {
      width: 280px;
    }

    &::before {
      content: '';
      display: block;
      padding-top: 100%;
    }

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.3s;
    }
  }

  &__content {
    flex: 1;

    h2 {
      color: var(--cl-gold);
      font-variant-numeric: oldstyle-nums proportional-nums;
      font-family: var(--font-main);
      font-size: ac(44px, 32px);
      font-weight: 500;
      line-height: 1.08;
      margin-bottom: 24px;
    }
  }

  &__descr {
    & > * {
      color: var(--cl-navy);
      font-feature-settings: 'liga' off;
      font-family: var(--font-main);
      font-size: 20px;
      font-weight: 400;
      line-height: 1.4;
      letter-spacing: 0.6px;
    }

    & > * + p {
      margin-top: 20px;
    }
  }
}

.section-home-review {
  position: relative;
  background-color: var(--cl-navy);
  color: var(--cl-white);
  /*background: url('../../assets/images/home-review.jpg') no-repeat center/cover;*/
  overflow: hidden;

  &__bg {
    position: absolute !important;
    z-index: 1 !important;
    inset: 0;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &::before {
    content: '';
    position: absolute !important;

    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(10, 14, 39, 0.7);
    z-index: 2;
  }

  & > * {
    position: relative;
    z-index: 3;
  }

  &__decor {
    position: absolute;
    left: -312px;

    @media (max-width: 1370px) {
      left: -30%;
    }

    @media (max-width: 1300px) {
      left: -35%;
    }

    @media (max-width: 1240px) {
      left: -45%;
    }

    @media (max-width: 1150px) {
      left: -55%;
    }

    @media (max-width: 1070px) {
      left: -65%;
    }

    @media (max-width: 976px) {
      left: -75%;
    }

    @media (max-width: 890px) {
      left: -85%;
    }

    @media (max-width: 800px) {
      max-width: 615px;
      left: 50%;
      top: -910px !important;
      transform: translateX(-50%);
    }

    @media (max-width: 500px) {
      left: calc(50% + 50px);
      top: -410px;
    }
  }

  &__wr {
    padding: 103px 0;
    max-width: 613px;
    margin-left: auto;

    @media (max-width: 768px) {
      padding: 136px 0 40px;
    }
  }

  &__quotes {
    margin-bottom: 16px;
  }

  &__descr {
    margin-bottom: ac(32px, 24px);
    font-feature-settings: 'liga' off;
    font-family: var(--font-main);
    font-weight: 400;
    letter-spacing: ac(0.78px, 0.66px);
    --lc: 6;
    --fz: ac(26px, 22px);
    --lh: 1.4;
    font-size: var(--fz);
    line-height: var(--lh);
    max-height: calc(var(--fz) * var(--lh) * var(--lc));

    @media (max-width: 551px) {
      --lc: 9;
      max-height: calc(var(--fz) * var(--lh) * var(--lc));
    }
  }

  .simplebar-vertical {
    background: var(--cl-beige);
    width: 8px;
    right: -12px;
  }

  &-author {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &__name {
      font-variant-numeric: oldstyle-nums proportional-nums;
      font-family: var(--font-main);
      font-size: ac(32px, 28px);
      font-weight: 600;
      line-height: 1.4;
    }

    &__company {
      font-variant-numeric: oldstyle-nums proportional-nums;
      font-family: var(--font-main);
      font-size: ac(24px, 20px);
      font-weight: 600;
      line-height: 1.4;
    }
  }
}

.section-home-approach {
  background-color: var(--cl-navy-lt);
  color: var(--cl-white);

  &__wr {
    display: flex;
    gap: ac(56px, 32px);
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    h2 {
      font-feature-settings: 'liga' off;
      font-family: var(--font-main);
      font-size: ac(44px, 40px);
      font-weight: 500;
      line-height: 1.08;
      min-height: 50px;
      background: linear-gradient(90deg, #ead4a2 -0.02%, #a7904f 100.03%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &-content {
    flex: 1;
    padding: ac(64px, 40px) 0 ac(64px, 32px);
    padding-left: ac(108px, 24px);

    @media (max-width: 768px) {
      padding-right: 24px;
      padding-bottom: 0;
    }

    &__items {
      display: flex;
      flex-direction: column;
      gap: ac(20px, 16px);
      margin-top: ac(48px, 32px);
    }

    &__item {
      display: flex;
      flex-direction: column;
      gap: 12px;

      &:not(:last-of-type) {
        padding-bottom: ac(20px, 16px);
        border-bottom: 1px solid rgba(234, 212, 162, 0.3);
      }

      h3 {
        font-variant-numeric: oldstyle-nums proportional-nums;
        font-feature-settings: 'liga' off;
        font-family: var(--font-main);
        font-size: 22px;
        font-weight: 600;
        line-height: 1.2;
        letter-spacing: 0.66px;
      }

      p {
        font-feature-settings: 'liga' off;
        font-family: var(--font-main);
        font-size: ac(18px, 16px);
        font-weight: 400;
        line-height: 1.4;
        letter-spacing: 0.54px;
        opacity: 0.8;
      }
    }
  }

  &__pic {
    position: relative;
    overflow: hidden;
    width: ac(697px, 375px);

    @media (max-width: 768px) {
      width: 100%;
      height: 400px;
    }

    @media (max-width: 450px) {
      height: 307px;
    }

    &::before {
      content: '';
      display: block;
      padding-top: 121.8%;
    }

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.3s;
    }
  }
}

.section-home-promises {
  background-color: var(--cl-navy);
  color: var(--cl-white);
  padding: ac(108px, 40px) 0;

  &__wr {
    h2 {
      font-family: var(--font-main);
      font-size: ac(44px, 36px);
      font-weight: 500;
      line-height: 1.08;
      background: linear-gradient(90deg, #ead4a2 -0.02%, #a7904f 100.03%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      @media (max-width: 600px) {
        line-height: 1.32;
      }
    }
  }

  &__cards {
    display: flex;
    align-items: stretch;
    gap: ac(40px, 16px);
    margin-top: ac(64px, 32px);

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  &-card {
    width: ac(592px, 327px);
    position: relative;
    flex: 1;
    padding: ac(20px, 8px);

    &::before {
      content: '';
      position: absolute;
      inset: 0;
      border: 1px solid transparent;
      border-radius: ac(32px, 20px);
      padding: ac(20px, 8px);
      background: linear-gradient(90.42deg, #ead4a2 -0.02%, #a7904f 100.03%);
      -webkit-mask: linear-gradient(#fff 0 0) padding-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
      pointer-events: none;
    }

    @media (max-width: 768px) {
      width: 100%;
    }

    &__wr {
      padding: ac(40px, 20px) ac(48px, 20px) ac(40px, 32px);
      position: relative;
      border-radius: 12px;
      height: 100%;

      &::before {
        content: '';
        position: absolute;
        inset: 0;
        border: 1px solid transparent;
        border-radius: 12px;
        padding: ac(40px, 20px) ac(48px, 20px) ac(40px, 32px);
        background: linear-gradient(90.42deg, #ead4a2 -0.02%, #a7904f 100.03%);
        -webkit-mask: linear-gradient(#fff 0 0) padding-box,
          linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        pointer-events: none;
      }
    }

    h3 {
      font-variant-numeric: oldstyle-nums proportional-nums;
      font-family: var(--font-main);
      font-size: ac(32px, 26px);
      font-weight: 600;
      line-height: 1.2;
      margin-bottom: 4px;
    }

    h4 {
      font-family: var(--font-main);
      font-size: ac(22px, 20px);
      font-weight: 600;
      line-height: 1.4;
      letter-spacing: 0.66px;
      opacity: 0.8;
      background: linear-gradient(90deg, #ead4a2 -0.02%, #a7904f 100.03%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &-card-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 20px;

    &__item {
      display: flex;
      align-items: center;
      gap: ac(16px, 10px);
      min-height: 40px;

      svg {
        width: ac(22px, 18px);
        height: ac(22px, 18px);
        flex-shrink: 0; 
    }

      p {
        font-family: var(--font-main);
        font-size: ac(18px, 16px);
        font-weight: 400;
        line-height: 1.4;
        letter-spacing: 0.54px;
        opacity: 0.8;
      }
    }
  }
}

.section-home-services {
  background-color: var(--cl-white);
  padding: ac(108px, 40px) 0 ac(108px, 32px);

  &__wr {
    h2 {
      color: var(--cl-navy);
      font-family: var(--font-main);
      font-size: ac(56px, 36px);
      font-weight: 600;
      line-height: 1.08;
      text-align: center;

      @media (max-width: 600px) {
        text-align: left;
      }
    }

    .home-services-card {
      position: relative;
      height: 100%;
      min-height: ac(248px, 206px);
      padding: ac(36px, 24px);
      border-radius: 24px;

      &::before {
        position: absolute;
        inset: 0;
        content: '';
        z-index: -1;
        background: linear-gradient(to left, #ead4a2 0%, #a7904f 100%);
        -webkit-mask: linear-gradient(#fff 0 0) content-box,
          linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        padding: 3px;
        border-radius: inherit;
      }

      @media (max-width: 768px) {
        min-height: 206px;
      }

      @media (max-width: 374px) {
        min-height: 280px;
      }

      h3 {
        position: relative;
        color: var(--cl-navy);
        font-variant-numeric: oldstyle-nums proportional-nums;
        font-family: var(--font-main);
        font-size: ac(32px, 28px);
        font-weight: 600;
        line-height: 1.2;
        padding-bottom: ac(20px, 16px);

        &::after {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 1px;
          background: linear-gradient(
            90deg,
            rgba(234, 212, 162, 0.4) 0%,
            rgba(167, 144, 79, 0.4) 100%
          );
        }
      }

      &__descr {
        --lc: 4;
        --fz: 20px;
        --lh: 1.4;

        margin-top: 12px;
        color: var(--cl-navy);
        font-feature-settings: 'liga' off;
        font-family: var(--font-main);
        font-size: var(--fz);
        line-height: var(--lh);
        font-weight: 400;
        letter-spacing: 0.6px;
        opacity: 0.8;
        max-height: calc(var(--fz) * var(--lh) * var(--lc));
      }
    }
  }

  .home-services-swiper-wr {
    margin-top: ac(64px, 24px);
  }

  .swiper {
    overflow: visible !important;

    &-slide {
      @media (max-width: 800px) {
        width: ac(392px, 315px);
      }

      @media (max-width: 374px) {
        width: 260px;
      }
    }
  }

  .swiper-wrapper {
    @media (min-width: 802px) {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      align-items: center;
      justify-items: center;
      gap: 24px;
    }
  }

  .swiper-buttons {
    margin-top: 24px;

    @media (min-width: 802px) {
      display: none;
    }
  }

  .simplebar-vertical {
    background: var(--cl-beige);
    width: 8px;
  }
}

.section-home-team {
  background-color: var(--cl-navy-lt);
  padding: ac(108px, 40px) 0 ac(108px, 32px);

  &__wr {
    h2 {
      font-feature-settings: 'liga' off;
      font-family: var(--font-main);
      font-size: ac(24px, 17px);
      font-weight: 400;
      line-height: 1.4;
      letter-spacing: 1.92px;
      text-transform: uppercase;
      background: linear-gradient(90deg, #ead4a2 -0.02%, #a7904f 100.03%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-bottom: ac(24px, 8px);
    }

    h3 {
      color: var(--cl-white);
      font-variant-numeric: oldstyle-nums proportional-nums;
      font-family: var(--font-main);
      font-size: ac(44px, 36px);
      font-weight: 500;
      line-height: 1.08;
    }
  }

  .home-team-card {
    background-color: var(--cl-navy);
    min-height: ac(580px, 483px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 20px;

    &__pic {
      position: relative;
      overflow: hidden;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;

      &::before {
        content: '';
        display: block;
        padding-top: 100%;
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          180deg,
          rgba(10, 14, 39, 0) 75%,
          #0a0e27 100%
        );
        z-index: 2;
      }

      img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: 0.3s;
        z-index: 1;
      }
    }

    &-content {
      position: relative;
      padding: ac(32px, 24px) ac(40px, 24px);

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 4px;
        background: linear-gradient(to right, #ead4a2, #a7904f);
        pointer-events: none;
      }

      &__name {
        color: var(--cl-white);
        font-variant-numeric: oldstyle-nums proportional-nums;
        font-family: var(--font-main);
        font-size: ac(32px, 28px);
        font-weight: 600;
        line-height: 1.2;
      }

      &__role {
        font-variant-numeric: oldstyle-nums proportional-nums;
        font-family: var(--font-main);
        font-size: ac(22px, 17px);
        font-weight: 600;
        line-height: 1.2;
        letter-spacing: 0.66px;
        background: linear-gradient(90deg, #ead4a2 -0.02%, #a7904f 100.03%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-top: 8px;
      }

      &__socials {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 23px;
        background: linear-gradient(90deg, #ead4a2 -0.02%, #a7904f 100.03%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-top: 24px;

        a {
          margin: 9.5px;
          cursor: pointer;

          i {
            transition: all 0.3s ease;
            font-size: 25px;
            background: linear-gradient(90deg, #ead4a2 -0.02%, #a7904f 100.03%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          &:hover {
            i {
              background: var(--cl-white);
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
        }
      }

      &__line {
        width: 100%;
        height: 1px;
        background: linear-gradient(90deg, #ead4a2 -0.02%, #a7904f 100.03%);
        margin-left: 10px;
      }
    }
  }

  .home-team-swiper-wr {
    position: relative;
    margin-top: ac(64px, 24px);

    .swiper {
      overflow: visible !important;
    }

    .swiper-slide {
      width: ac(380px, 303px);

      @media (max-width: 374px) {
        width: 260px;
      }
    }

    .swiper-buttons {
      @media (max-width: 1024px) {
        margin-top: 24px;
      }

      @media (min-width: 1025px) {
        position: absolute;
        top: -112px;
        right: -5px;
      }

      .swiper-button {
        color: var(--cl-beige);
        font-size: 20px;
        border: 1px solid transparent;
        border-radius: 50%;
        background: linear-gradient(#0a0e27 0%, #0a0e27 100%) padding-box,
        linear-gradient(to right, #EAD4A2 0%, #A7904F 100%) border-box;

        &:hover {
          background: var(--cl-beige);
          color: var(--cl-black);
        }
      }
    }
  }
}

.section-home-contact {
  position: relative;
  padding: ac(108px, 32px) 0;
  background: linear-gradient(90deg, #a7904f 0%, #ead4a2 100%);

  @media (max-width: 600px) {
    background: linear-gradient(360deg, #a7904f 0%, #ead4a2 100%);
  }

  overflow: hidden;

  .decor {
    position: absolute;
    top: 61px;
    left: -350px;
    width: 100%;

    @media (max-width: 768px) {
      width: 558px;
      top: 700px;
      left: -210px;
    }
  }

  &__wr {
    display: flex;
    align-items: flex-start;
    gap: ac(64px, 38px);

    @media (max-width: 1150px) {
      flex-direction: column;
    }

    @media (max-width: 768px) {
      align-items: stretch;
    }
  }

  &__content {
    color: var(--cl-navy);
    width: ac(496px, 400px);

    @media (max-width: 768px) {
      width: 100%;
    }

    h2 {
      font-feature-settings: 'liga' off;
      font-family: var(--font-main);
      font-size: ac(24px, 17px);
      font-weight: 400;
      line-height: 1.4;
      letter-spacing: 1.92px;
      text-transform: uppercase;
    }

    h3 {
      position: relative;
      font-variant-numeric: oldstyle-nums proportional-nums;
      font-family: var(--font-main);
      font-size: ac(64px, 48px);
      font-weight: 500;
      line-height: 1.08;
      margin-top: ac(24px, 8px);
      padding-bottom: ac(24px, 20px);
    }
  }

  &__descr {
    margin-top: ac(24px, 20px);

    & > * {
      font-feature-settings: 'liga' off;
      font-family: 'Playfair Display';
      font-size: ac(20px, 17px);
      font-weight: 400;
      line-height: 1.4;
      letter-spacing: 0.6px;
      opacity: 0.8;
    }
  }

  &-form {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    @media (min-width: 1024px) {
      row-gap: 15px;
      margin-top: -15px;
    }

    @media (max-width: 1150px) {
      max-width: 664px;
      width: 100%;
    }

    @media (max-width: 768px) {
      flex-direction: column;
      row-gap: 15px;

      input,
      textarea {
        width: 100%;
      }
    }

    input,
    textarea {
      @mixin transparent-bg-input
        linear-gradient(90deg, #a7904f 0%, #ead4a2 100%);
      padding: 19px 12px 19px 16px;
      color: var(--Navy, #0a0e27);
      font-variant-numeric: lining-nums proportional-nums;
      font-family: var(--font-main);
      font-size: 14px;
      font-weight: 600;
      line-height: 1.4;
      background: transparent;
      border: 1px solid var(--cl-navy);
      border-radius: 4px;

      &::placeholder {
        color: var(--Navy, #0a0e27);
        opacity: 0.8;
      }
    }

    input {
      width: 322px;
      height: 48px;

      @media (max-width: 768px) {
        width: 100%;
      }
    }

    textarea {
      resize: none;
      width: 664px;

      @media (max-width: 768px) {
        width: 100%;
      }

      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--cl-navy);
      }

      &::-webkit-scrollbar-track {
        background-color: var(--cl-gold);
      }
    }

    &__input {
      display: flex;
      flex-direction: column;
      gap: 12px;

      @media (max-width: 600px) {
        gap: 6px;
      }
    }

    &__textarea {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    label {
      opacity: 0.8;
      color: var(--cl-navy);
      font-variant-numeric: lining-nums proportional-nums;
      font-family: var(--font-main);
      font-size: 16px;
      font-weight: 400;
      line-height: 1.4;
      padding-left: 16px;
    }

    &__interact {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: ac(32px, 16px);
      margin-top: 20px;

      @media (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
      }

      @media (max-width: 600px) {
        margin-top: 0;
      }

      .meta-checkbox {
        max-width: 350px;
        padding: 0;

        &__box {
          @media (max-width: 768px) {
            min-width: 28px;
            width: 28px;
            height: 28px;
          }
        }
      }

      .combo-btn {
        & > :first-child {
          border-color: var(--cl-navy);
          color: var(--cl-navy);

          @media (max-width: 768px) {
            width: 290px;
            text-align: center !important;
          }

          @media (max-width: 374px) {
            width: 240px;
            margin: 0 auto;
          }
        }
        & > :last-child {
          border-color: var(--cl-navy);
          color: var(--cl-navy);
        }

        &:hover > :first-child {
          color: var(--cl-white);
        }

        &:hover > :last-child {
          color: var(--cl-white);
        }
      }
    }
  }
}

.section-home-testimonials {
  position: relative;
  padding: ac(108px, 40px) 0 ac(44px, 32px);

  .simplebar-vertical {
    background: var(--cl-beige);
    width: 8px;
    right: -12px;
  }

  &__bg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    max-width: 500px;
    width: 100%;

    @media (max-width: 1024px) {
      left: -10%;
    }

    @media (max-width: 800px) {
      left: -20%;
    }

    @media (max-width: 768px) {
      left: -25%;
    }
  }

  &__pic {
    position: relative;
    overflow: hidden;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;

    @media (max-width: 1024px) {
      height: 550px;
    }

    @media (max-width: 768px) {
      height: 450px;
    }

    @media (max-width: 600px) {
      height: 430px;
    }

    @media (max-width: 425px) {
      height: 363px;
    }

    &::before {
      content: '';
      display: block;
      padding-top: 123.6%;
    }

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.3s;
    }
  }

  &__wr {
    h2 {
      color: var(--cl-gold);
      text-align: right;
      font-family: var(--font-main);
      font-size: ac(56px, 36px);
      font-weight: 600;
      line-height: 1.08;

      @media (max-width: 768px) {
        text-align: left;
      }
    }
  }

  .home-testimonials-card {
    position: relative;
    padding: ac(40px, 20px);
    background-color: var(--cl-navy);
    height: ac(457px, 383px);
    border-radius: 20px;
    box-shadow: 0px 0px 200px 0px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    transform: scale(0.95);

    &__decr {
      color: var(--cl-white);
      font-feature-settings: 'liga' off;
      font-family: var(--font-main);
      font-weight: 400;
      letter-spacing: 0.66px;
      opacity: 0.8;
      --fz: ac(22px, 17px);
      --lh: 1.4;
      --lc: 10;
      font-size: var(--fz);
      line-height: var(--lh);
      max-height: calc(var(--fz) * var(--lh) * var(--lc));

      @media (min-width: 1200px) {
        --lc: 8;
      }

      @media (max-width: 350px) {
        --lc: 12;
      }
    }

    &-author {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 8px;
      position: absolute;
      bottom: ac(40px, 20px);
      left: ac(40px, 20px);
      padding: ac(25px, 13px) ac(32px, 28px);
      width: calc(100% - ac(80px, 40px));

      &::before {
        content: '';
        position: absolute;
        inset: 0;
        border: 1px solid transparent;
        border-radius: 10000px;
        padding: ac(25px, 13px) ac(32px, 28px);
        background: linear-gradient(90.42deg, #ead4a2 -0.02%, #a7904f 100.03%);
        -webkit-mask: linear-gradient(#fff 0 0) padding-box,
          linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
      }

      &__name {
        color: var(--cl-white);
        font-variant-numeric: oldstyle-nums proportional-nums;
        font-family: var(--font-main);
        font-size: ac(26px, 22px);
        font-weight: 600;
        line-height: 1.2;
      }

      &__location {
        font-variant-numeric: oldstyle-nums proportional-nums;
        font-family: var(--font-main);
        font-size: 16px;
        font-weight: 500;
        line-height: 1.2;
        letter-spacing: 0.48px;
        background: linear-gradient(90deg, #ead4a2 -0.02%, #a7904f 100.03%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  .home-testimonials-swiper-wr {
    margin-top: ac(64px, 24px);

    .swiper {
      overflow: visible !important;
      max-width: 520px;

      @media (max-width: 1024px) {
        max-width: 420px;
      }

      @media (max-width: 768px) {
        max-width: 370px;
      }

      @media (max-width: 551px) {
        max-width: 310px;
      }
    }

    .swiper-slide {
      transition: 0.3s all ease;

      @media (max-width: 600px) {
        .home-testimonials-card {
          height: 418px !important;
        }
      }

      @media (max-width: 370px) {
        .home-testimonials-card {
          height: 480px !important;
        }
      }

      &-active {
        .home-testimonials-card {
          height: 450px;
        }
      }
    }

    .swiper-buttons {
      position: static;
      margin: 61px 0 0 auto;

      @media (max-width: 768px) {
        margin: 24px auto 0;
      }

      .swiper-button {
        border: 1px solid transparent;
        border-radius: 50%;
        background: linear-gradient(#ffffff 0%, #ffffff 100%) padding-box,
        linear-gradient(to right, #EAD4A2 0%, #A7904F 100%) border-box;

        &::before {
          font-size: 20px;
          color: var(--cl-navy);
        }
      }
    }
  }
}
