/* ../../assets/ */

@font-face {
  font-family: 'icomoon';
  src:  url('../../assets/fonts/icomoon.eot?74064x');
  src:  url('../../assets/fonts/icomoon.eot?74064x#iefix') format('embedded-opentype'),
  url('../../assets/fonts/icomoon.ttf?74064x') format('truetype'),
  url('../../assets/fonts/icomoon.woff?74064x') format('woff'),
  url('../../assets/fonts/icomoon.svg?74064x#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-footer-instagram-icon:before {
  content: "\e905";
}
.icon-arrow-left-icon:before {
  content: "\e900";
}
.icon-arrow-right-icon:before {
  content: "\e901";
}
.icon-email-icon:before {
  content: "\e902";
}
.icon-footer-email-icon:before {
  content: "\e903";
}
.icon-footer-facebook-icon:before {
  content: "\e904";
}
.icon-footer-linkedin-icon:before {
  content: "\e906";
}
.icon-footer-location-icon:before {
  content: "\e907";
}
.icon-footer-phone-icon:before {
  content: "\e908";
}
.icon-footer-twitter-icon:before {
  content: "\e909";
}
.icon-footer-youtube-icon:before {
  content: "\e90a";
}
.icon-linkedin-icon:before {
  content: "\e90b";
}
.icon-phone-icon:before {
  content: "\e90c";
}
.icon-plus-icon:before {
  content: "\e90d";
}

